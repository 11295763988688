import React from "react";
import "lazysizes";
import { Helmet } from "react-helmet";
import "../styles/screen.scss";
import HeaderSection from "../components/HeaderSection/";
import HeroSection from "../components/HeroSection/";
import AboutSection from "../components/AboutSection/";
import Sweepstakes from "../components/SweepSection/";
import SocialSection from "../components/SocialSection/";
import CocktailSection from "../components/CocktailSection/";
import Footer from "../components/Footer/";

export default function Home() {
  return <main className="site-wrapper">
    <Helmet>
      <title>St~Germain Make The Moment Bloom</title>
      <meta name="description" content=""/>
      <meta property="og:title" content="St~Germain Make The Moment Bloom" />
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="" />
      <meta property="og:image" content="" />
      <meta property="og:locale" content="en_US"/>
      <meta property="og:url" content="" />
      <link rel="canonical" href=""/>
    </Helmet>
    <HeaderSection />
    <HeroSection />
    <AboutSection />
    <Sweepstakes />
    <SocialSection />
    <CocktailSection />
    <Footer />
  </main>
}
