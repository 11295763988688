import "./AboutSection.scss";
import AboutDesktopImage from "./assets/STG20_Desktop_2880_Module2_YellowSpritz.jpg";
import AboutMobileImage from "./assets/STG20_Mobile_960_Module2_YellowSpritz.jpg";
import React from "react";


const AboutSection = () => (
  <section className="section mtmb-about-section">
    <span className="mtmb-elderflower-1"></span>
    <span className="mtmb-elderflower-2"></span>
    <span className="mtmb-elderflower-3"></span>
    <div className="mtmb-about-section__inner">
      <aside className="mtmb-about-section__aside">
      </aside>
      <div className="mtmb-about-section__left">
        <h2 className="mtmb-about-section__heading mtmb-header-1" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">EFFORTLESSLY ELEVATE ANY OCCASION</h2>
        <span className="mtmb-fancy-spacer" data-sal="fade" data-sal-duration="800" data-sal-easing="ease"></span>
        <div className="mtmb-about-section__body">
          <div className="hide-desktop">
            <picture>
                <source data-srcset={AboutDesktopImage} media="(min-width: 568px)" />
                <img className="mtmb-about-section__img lazyload" data-src={AboutMobileImage} alt="girl holding glass with ST~GERMAIN bottle" />
            </picture>
          </div>
          <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">A dash of ST~GERMAIN will add a little je ne sais quoi to your favorite cocktail. With up to 1,000 elderflowers in every bottle, it's the easiest way to refine any cocktail.</p>
          <p className="mtmb-body" data-sal="fade" data-sal-duration="800" data-sal-easing="ease">It's time to spritz up your home bar cart, and make the moment bloom.</p>
        </div>
      </div>
      <div className="mtmb-about-section__right">
          <picture>
              <source data-srcset={AboutDesktopImage} media="(min-width: 568px)" />
              <img className="mtmb-about-section__img lazyload" data-src={AboutMobileImage} alt="girl holding glass with ST~GERMAIN bottle" />
          </picture>
      </div>
    </div>
    <span className="mtmb-elderflower-4"></span>
  </section>
)

export default AboutSection;
